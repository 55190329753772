import React, { useState } from 'react'
import { Link } from 'gatsby'

import Logo from '../../images/logoBlack.svg'
import warningSign from '../../images/coronaReminder/warningSign.svg'
import cardArrow from '../../images/coronaReminder/cardArrow.svg'
import coronaExpandable from '../../images/coronaReminder/coronaExpandable.svg'
import digitalID from '../../images/coronaReminder/digitalId.jpg'
import smsExample from '../../images/coronaReminder/smsExample.jpg'
import appStoreLink from '../../images/coronaReminder/appStoreLink.jpg'
import googlePlayLink from '../../images/coronaReminder/googlePlayLink.jpg'
import tatarstan1 from '../../images/coronaReminder/tatarstan1.jpg'
import tatarstan2 from '../../images/coronaReminder/tatarstan2.jpg'
import moscowRegionImg from '../../images/coronaReminder/moscowRegionImg.jpg'
import spbRegionImg from '../../images/coronaReminder/spbRegionImg.jpg'
import moscowImg from '../../images/coronaReminder/moscowImg.svg'
import tatarstanRegionImg from '../../images/coronaReminder/tatarstanRegionImg.svg'
import nizhegorodRegionImg from '../../images/coronaReminder/nizhegorodRegionImg.jpg'
import samaraRegionImg from '../../images/coronaReminder/samaraRegionImg.jpg'
import krasnodarRegionImg from '../../images/coronaReminder/krasnodarRegionImg.jpg'
import bashkortostanRegionImg from '../../images/coronaReminder/bashkortostanRegionImg.jpg'
import astrakhanRegionImg from '../../images/coronaReminder/astrakhanRegionImg.jpg'
import smsExample2 from '../../images/coronaReminder/smsExample2.jpg'

import * as styles from './coronaReminder.module.less'

const CoronaReminder = () => {
  const [visibleSettings, setVisibleSettings] = useState([
    { id: 'first', open: false },
    { id: 'second', open: false },
    { id: 'third', open: false },
    { id: 'fourth', open: false },
    { id: 'fifth', open: false },
  ])

  const toggleContent = (string) => {
    setVisibleSettings(
      visibleSettings.map((item) =>
        item.id === string ? { ...item, open: !item.open } : item
      )
    )
  }

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <Link to={'/'} className={styles.logolinkk}>
            <img src={Logo} alt={'logo'} loading="lazy" />
          </Link>
        </div>
        <h3 className={styles.header}>
          Памятка по&nbsp;режиму работы и&nbsp;пропускной системе
        </h3>
        <p className={styles.generalText}>
          Команда Ипотека.Центра делает всё, чтобы процесс заключения ипотечных
          сделок оставался максимально удобным, комфортным и&nbsp;безопасным для
          всех её&nbsp;сторон.
        </p>
        <p className={styles.generalText}>
          Мы&nbsp;подготовили ответы на&nbsp;самые важные вопросы в&nbsp;период
          режима самоизоляции и&nbsp;действия электронных пропусков.
        </p>

        <div className={styles.warningBlock}>
          <img src={warningSign} className={styles.warningImage} />
          <p className={styles.warningText}>
            Пропускной режим на&nbsp;момент публикации (22&nbsp;апреля 2020)
            действует в&nbsp;Москве, Московской области и&nbsp;Татарстане.
            В&nbsp;остальных регионах&nbsp;РФ пропускная система ещё
            не&nbsp;введена или носит рекомендательный характер.
            Но&nbsp;в&nbsp;связи со&nbsp;сложной эпидемиологической ситуацией
            представители уполномоченных органов могут попросить подтвердить
            цель выхода из&nbsp;дома.
          </p>
        </div>

        <div className={styles.cardsBlock}>
          <a href="#whereToDeal">
            <div className={styles.card}>
              <p className={styles.cardText}>
                Где и&nbsp;как сейчас проводятся сделки в&nbsp;банках-партнёрах?
                <img src={cardArrow} className={styles.cardArrow} />
              </p>
            </div>
          </a>

          <a href="#howToRegister">
            <div className={styles.card}>
              <p className={styles.cardText}>
                Как оформить пропуск, чтобы приехать на&nbsp;сделку?
                <img src={cardArrow} className={styles.cardArrow} />
              </p>
            </div>
          </a>
          <a href="#ifFromRegion">
            <div className={styles.card}>
              <p className={styles.cardText}>
                Пустят&nbsp;ли на&nbsp;сделку в&nbsp;Москву или&nbsp;МО, если
                я&nbsp;еду из&nbsp;региона?
                <img src={cardArrow} className={styles.cardArrow} />
              </p>
            </div>
          </a>
          <a href="#whatToTellPolice">
            <div className={styles.card}>
              <p className={styles.cardText}>
                Что говорить полиции, если меня остановят на&nbsp;пути
                на&nbsp;сделку?
                <img src={cardArrow} className={styles.cardArrow} />
              </p>
            </div>
          </a>
          <a href="#moreInfo">
            <div className={styles.card}>
              <p className={styles.cardText}>
                Больше информации по&nbsp;пропускным режимам в&nbsp;городах
                <img src={cardArrow} className={styles.cardArrow} />
              </p>
            </div>
          </a>
        </div>

        <div id="whereToDeal" className={styles.infoBlock}>
          <h4 className={styles.subheader}>
            Где и&nbsp;как сейчас проводятся сделки в&nbsp;банках-партнёрах?
          </h4>
          <p className={styles.infoBlock_text}>
            Важно, что информация по&nbsp;проведению сделок меняется
            в&nbsp;режиме реального времени. Актуальные сведения
            по&nbsp;конкретным банкам &mdash; уточняйте у&nbsp;своих кураторов.
          </p>
          <p className={styles.infoBlock_text}>
            Оформление сделок с&nbsp;банком ДОМ.РФ проходит в&nbsp;стандартном
            режиме в&nbsp;офисах Ипотека.Центра.
          </p>
          <p className={styles.infoBlock_text}>
            Мы&nbsp;ввели дополнительные меры защиты и&nbsp;безопасности для
            сотрудников и&nbsp;клиентов. После каждой сделки выдерживается
            обязательный временной промежуток для качественной дезинфекции
            помещения.
          </p>
          <p className={styles.infoBlock_text}>
            Настоятельно рекомендуем ограничить количество очных участников
            сделки только лицами, непосредственно участвующими
            в&nbsp;подписании. Остальные заинтересованные стороны, включая
            агентов по&nbsp;встречным покупкам и&nbsp;представителей продавца,
            смогут присутствовать в&nbsp;формате дистанционного подключения
            по&nbsp;видеосвязи.
          </p>
          <p className={styles.infoBlock_text}>
            Но&nbsp;один из&nbsp;самых удобных способов провести сделку
            в&nbsp;текущей ситуации &mdash;воспользоваться&nbsp;
            <b>услугой &laquo;выездная сделка&raquo;</b>. Наши специалисты могут
            приехать к&nbsp;нотариусу, и&nbsp;вся сделка пройдёт в&nbsp;одном
            месте без лишних контактов и&nbsp;поездок со&nbsp;стороны клиента.
            Подробнее об&nbsp;услуге можно уточнить у&nbsp;своего куратора.
          </p>
          <p className={styles.infoBlock_text}>
            Вне зависимости от&nbsp;того, где будет происходить процесс
            подписания сделки, мы&nbsp;просим не&nbsp;пренебрегать мерами
            индивидуальной защиты, использовать маски, антисептики или
            одноразовые перчатки для рук. На&nbsp;входе в&nbsp;офис
            Ипотека.Центра наши сотрудники также оснащены всем необходимым.
          </p>
        </div>

        <div id="howToRegister" className={styles.infoBlock}>
          <h4 className={styles.subheader}>
            Как оформить пропуск, чтобы приехать на&nbsp;сделку?
          </h4>
          <div className={styles.infoBlock_line}></div>
          <div
            className={styles.infoBlock_expandButton}
            onClick={() => toggleContent('first')}
          >
            <img
              className={
                visibleSettings.find((item) => item.id === 'first').open
                  ? styles.infoBlock_arrow
                  : styles.infoBlock_arrowUp
              }
              src={coronaExpandable}
            />
            <p className={styles.infoBlock_expandText}>В Москве</p>
          </div>
          <div
            className={
              visibleSettings.find((item) => item.id === 'first').open
                ? styles.infoBlock_content
                : styles.displayNone
            }
          >
            <p className={styles.infoBlock_text_expand}>
              С&nbsp;15&nbsp;апреля для поездок по&nbsp;Москве и&nbsp;Московской
              области на&nbsp;личном и общественном транспорте наличие
              специального цифрового пропуска является обязательным.
            </p>
            <p className={styles.infoBlock_text_expand}>
              Чтобы получить пропуск для поездки на&nbsp;сделку необходимо
              самостоятельно оформить разовый &laquo;Пропуск для поездок
              в&nbsp;личных целях&raquo;. В&nbsp;качестве цели можно указать
              &laquo;Посещение кредитной организации&raquo;. Пропуск выдается
              на&nbsp;один день&nbsp;и&nbsp;
              <b>
                даёт вам возможность спокойно приехать на&nbsp;сделку
                и&nbsp;вернуться обратно домой.
              </b>
            </p>
            <p className={styles.infoBlock_text_expand}>
              С&nbsp;22&nbsp;апреля, при оформлении пропуска, граждане обязаны
              вносить данные проездных билетов.
            </p>
            <p className={styles.infoBlock_text}>
              Оформить пропуск с&nbsp;привязкой к&nbsp;номеру карты нужно
              не&nbsp;позже 5&nbsp;часов до&nbsp;поездки. Это необходимо, чтобы
              сведения были внесены в&nbsp;базы данных городских транспортных
              служб.
            </p>
            <p className={styles.infoBlock_text_expand}>
              Для поездок в&nbsp;общественном транспорте можно использовать
              только электронные карты&nbsp;&mdash; &laquo;Тройка&raquo;,
              &laquo;Стрелка&raquo;, месячный единый проездной билет, социальные
              карты москвича и&nbsp;жителя Московской области. Проехать
              на&nbsp;общественном транспорте с&nbsp;предъявлением других
              проездных документов будет невозможно.
            </p>
            <p className={styles.infoBlock_text_expand}>
              Номер электронной карты должен быть включен в&nbsp;цифровой
              пропуск,&nbsp;&mdash; иначе её заблокирует валидатор или турникет.
              Тем, у&nbsp;кого нет электронной карты, до&nbsp;22&nbsp;апреля или
              до&nbsp;момента первой поездки её&nbsp;нужно оформить.
            </p>
            <p className={styles.infoBlock_text_expand}>
              Есть 3&nbsp;способа заказать пропуск в&nbsp;Москве.
            </p>

            <h4 className={styles.infoBlock_subheader}>
              Способ&nbsp;1. Через онлайн-сервис&nbsp;
              <a
                className={styles.externalLink}
                href="https://nedoma.mos.ru/"
                target="_blank"
              >
                nedoma.mos.ru
              </a>
            </h4>
            <p className={styles.infoBlock_text_expand}>
              Для этого нужно нажать на&nbsp;кнопку &laquo;Получить цифровой
              пропуск&raquo;, авторизоваться на&nbsp;портале, ввести необходимые
              данные и&nbsp;подать заявку на&nbsp;пропуск для поездок
              в&nbsp;личных целях.
            </p>

            <img className={styles.infoBlock_img} src={digitalID} />

            <p className={styles.infoBlock_text_expand}>
              Если Вы&nbsp;планируете ехать на&nbsp;личном автомобиле,
              то&nbsp;потребуется указать его номер. В&nbsp;случае поездки
              на&nbsp;общественном транспорте вы сможете указать номер карты
              &laquo;Тройка&raquo; или &laquo;Стрелка&raquo; (при наличии).
            </p>
            <p className={styles.infoBlock_text_expand}>
              Система отобразит на&nbsp;экране специальный код, который
              необходимо сохранить любым удобным для вас способом&nbsp;&mdash;
              записать, сфотографировать или сделать скриншот&nbsp;&mdash;
              и&nbsp;обязательно взять с&nbsp;собой.
            </p>
            <p className={styles.infoBlock_text}>
              Данные пропуска также будут направлены на&nbsp;вашу электронную
              почту, если она указана в&nbsp;заявке.
            </p>

            <h4 className={styles.infoBlock_subheader}>
              Способ&nbsp;2. С&nbsp;помощью СМС на&nbsp;номер&nbsp;
              <a className={styles.externalLink_nounderline}>7377</a>
            </h4>
            <p className={styles.infoBlock_text_expand}>
              Цифровой код придет в&nbsp;ответном СМС.
            </p>
            <div className={styles.smsLayOut}>
              <p className={styles.smsLayOut_header}>
                Шаблон СМС сообщения для поездки на&nbsp;сделку:
              </p>
              <p className={styles.smsLayOut_text}>
                Пропуск*3*код документа*серия паспорта*номер паспорта*номер
                машины (если используется)*номер карты Тройка (если
                используется)*номер карты Стрелка (если используется)*Посещение
                банка*адрес пункта назначения.
              </p>
            </div>

            <p className={styles.infoBlock_text_expand}>
              * &mdash; обязательный разделительный знак, который также
              проставляется в&nbsp;СМС
            </p>

            <p className={styles.infoBlock_text}>
              <span className={styles.infoBlock_position}>
                Код документа выберите из списка:
                <span className={styles.infoBlock_span}>
                  1 — Паспорт гражданина РФ;
                </span>
                <span className={styles.infoBlock_span}>
                  2 — Иностранный паспорт;
                </span>
                <span className={styles.infoBlock_span}>
                  3 — Иной документ.
                </span>
              </span>
            </p>

            <p className={styles.infoBlock_text_expand}>
              Если вы&nbsp;не&nbsp;используете автомобиль или карту
              &laquo;Тройка&raquo;/&laquo;Стрелка&raquo; между символами **
              ничего не&nbsp;заполняется. Сами символы обязательны.
            </p>

            <img className={styles.infoBlock_img} src={smsExample} />

            <h4 className={styles.infoBlock_subheader}>
              Способ&nbsp;3. Звонок в&nbsp;Единую справочную службу
              Правительства Москвы&nbsp;
              <a
                className={styles.externalLink_nowrap_nounderline}
                href="tel:+7-495-777-77-77"
              >
                +7&nbsp;(495)&nbsp;777-77-77
              </a>
            </h4>
            <p className={styles.infoBlock_text_expand}>
              Оператор контакт-центра запросит необходимую информацию: адрес,
              откуда вы&nbsp;поедете на&nbsp;сделку и&nbsp;куда должны будете
              вернуться, адрес места сделки и&nbsp;цель поездки. С&nbsp;ваших
              слов оператор самостоятельно заполнит данные для цифрового
              пропуска. После чего продиктует код цифрового пропуска. Код
              обязательно запишите или сохраните любым удобным способом.
            </p>
          </div>

          <div className={styles.infoBlock_line}></div>

          <div
            className={styles.infoBlock_expandButton}
            onClick={() => toggleContent('second')}
          >
            <img
              className={
                visibleSettings.find((item) => item.id === 'second').open
                  ? styles.infoBlock_arrow
                  : styles.infoBlock_arrowUp
              }
              src={coronaExpandable}
            />
            <p className={styles.infoBlock_expandText}>В Московской области</p>
          </div>
          <div
            className={
              visibleSettings.find((item) => item.id === 'second').open
                ? styles.infoBlock_content
                : styles.displayNone
            }
          >
            <p className={styles.infoBlock_text_expand}>
              Для того, чтобы приехать на&nbsp;сделку в&nbsp;Москву из&nbsp;МО,
              вы&nbsp;можете возпользоваться общественным транспортом или
              приехать на&nbsp;личном автомобиле.
            </p>
            <p className={styles.infoBlock_text_expand}>
              С&nbsp;22&nbsp;апреля в&nbsp;Подмосковье начнут действовать новые
              правила работы цифровых пропусков для тех, кто передвигается
              на&nbsp;общественном транспорте. Расплатиться за&nbsp;проезд
              в&nbsp;общественном транспорте можно только транспортной или
              социальной картой. Банковские карты, телефонные приложения,
              а&nbsp;также наличная оплата работать не&nbsp;будут. Для проезда
              на&nbsp;общественном транспорте нужно указывать номер карты
              &laquo;Стрелка&raquo; или&nbsp;&laquo;Тройка&raquo;.
            </p>
            <p className={styles.infoBlock_text}>
              Если у&nbsp;вас нет ни&nbsp;&laquo;Тройки&raquo;,
              ни&nbsp;&laquo;Стрелки&raquo;, вам нужно её&nbsp;приобрести. Все
              адреса пунктов продажи размещены на&nbsp;сайтах обеих транспортных
              карт.
            </p>
            <p className={styles.infoBlock_text_expand}>
              Тройка&nbsp;
              <a
                href="https://mosmetro.ru/tariffs/troika/"
                className={styles.infoBlock_link}
                target="_blank"
              >
                https://mosmetro.ru/tariffs/troika/
              </a>
            </p>
            <p className={styles.infoBlock_text_expand}>
              Стрелка&nbsp;
              <a
                href="https://strelkacard.ru/"
                className={styles.infoBlock_link}
                target="_blank"
              >
                https://strelkacard.ru/
              </a>
            </p>
            <h4 className={styles.infoBlock_subheader}>
              Способ&nbsp;1. Через приложение&nbsp;
              <a className={styles.externalLink_nounderline}>
                &laquo;Госуслуги СТОП Коронавирус&raquo;
              </a>
            </h4>
            <p className={styles.infoBlock_text}>
              Для того, чтобы приехать на&nbsp;сделку в&nbsp;Москву из&nbsp;МО,
              нужно скачать в&nbsp;Google Play или AppStore приложение
              &laquo;Госуслуги СТОП Коронавирус&raquo; и&nbsp;заполнить форму
              с&nbsp;указанием следующих данных:
            </p>
            <ul>
              <li>
                <div className={styles.listStyle}></div>ФИО
              </li>
              <li>
                <div className={styles.listStyle}></div>Серия и номер паспорта
              </li>
              <li>
                <div className={styles.listStyle}></div>Номер телефона
              </li>
              <li>
                <div className={styles.listStyle}></div>Адрес электронной почты
                (по желанию)
              </li>
              <li>
                <div className={styles.listStyle}></div>Госномер транспортного
                средства (если планируете ехать на личном транспорте)
              </li>
              <li>
                <div className={styles.listStyle}></div>Номер карты «Стрелка»
                или «Тройка» (если планируете ехать на общественном транспорте)
              </li>
              <li>
                <div className={styles.listStyle}></div>Цель передвижения —
                Посещение кредитной организации
              </li>
              <li>
                <div className={styles.listStyle}></div>Маршрут передвижения
                (адрес места жительства и адрес места назначения, например,
                Пресненская набережная д. 6с2, оф 3624, если вы едете на сделку
                к нам в офис или адрес банка, в котором у вас назначена сделка).
              </li>
            </ul>
            <div className={styles.storeLinksContainer}>
              <a
                href="https://apps.apple.com/ru/app/госуслуги-стоп-коронавирус/id1504868945?l=en"
                target="_blank"
                className={styles.storeLink}
              >
                <img src={appStoreLink} className={styles.storeLinkImg} />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.minsvyaz.gosuslugi.stopcorona&hl=ru&gl=ru"
                target="_blank"
                className={styles.storeLink}
              >
                <img src={googlePlayLink} className={styles.storeLinkImg} />
              </a>
            </div>

            <h4 className={styles.infoBlock_subheader}>
              Способ&nbsp;2. С&nbsp;помощью СМС на&nbsp;номер&nbsp;
              <a className={styles.externalLink_nounderline}>0250</a>
            </h4>
            <p className={styles.infoBlock_text_expand}>
              Цифровой код придет в&nbsp;ответном СМС.
            </p>
            <div className={styles.smsLayOut}>
              <p className={styles.smsLayOut_header}>
                Шаблон СМС сообщения для поездки на&nbsp;сделку:
              </p>
              <p className={styles.smsLayOut_text_expand}>
                Пропуск*3*код документа*серия паспорта*номер паспорта*дата
                рождения*номер машины (если используется)*номер карты Тройка
                (если используется)*номер карты Стрелка (если
                используется)*Посещение банка*адрес пункта назначения.
              </p>
            </div>

            <p className={styles.infoBlock_text_expand}>
              * &mdash; обязательный разделительный знак, который также
              проставляется в&nbsp;СМС
            </p>

            <p className={styles.infoBlock_text}>
              <span className={styles.infoBlock_position}>
                Код документа выберите из списка:
                <span className={styles.infoBlock_span}>
                  1 — Паспорт гражданина РФ;
                </span>
                <span className={styles.infoBlock_span}>
                  2 — Иностранный паспорт;
                </span>
                <span className={styles.infoBlock_span}>
                  3 — Иной документ.
                </span>
              </span>
            </p>

            <p className={styles.infoBlock_text_expand}>
              Если вы&nbsp;не&nbsp;используете автомобиль или карту
              &laquo;Тройка&raquo;/&laquo;Стрелка&raquo;, между символами **
              ничего не&nbsp;заполняется. Сами символы обязательны.
            </p>

            <img className={styles.infoBlock_img} src={smsExample2} />
          </div>

          <div className={styles.infoBlock_line}></div>

          <div
            className={styles.infoBlock_expandButton}
            onClick={() => toggleContent('third')}
          >
            <img
              className={
                visibleSettings.find((item) => item.id === 'third').open
                  ? styles.infoBlock_arrow
                  : styles.infoBlock_arrowUp
              }
              src={coronaExpandable}
            />
            <p className={styles.infoBlock_expandText}>В Санкт-Петербурге</p>
          </div>
          <div
            className={
              visibleSettings.find((item) => item.id === 'third').open
                ? styles.infoBlock_content
                : styles.displayNone
            }
          >
            <p className={styles.infoBlock_text_expand}>
              Система электронных пропусков в&nbsp;Санкт-Петербурге
              не&nbsp;введена. Вы можете свободно приезжать на&nbsp;сделку без
              дополнительных разрешений. Если в&nbsp;пути вас остановили
              полицейские, просто назовите причину вашей поездки.
            </p>

            <p className={styles.infoBlock_text_expand}>
              Если у&nbsp;сотрудников полиции возникнут дополнительные вопросы,
              подтвердить сделку можно с&nbsp;помощью телефонного звонка
              в&nbsp;отделение банка или вашему куратору Ипотека.Центр.
            </p>
          </div>

          <div className={styles.infoBlock_line}></div>

          <div
            className={styles.infoBlock_expandButton}
            onClick={() => toggleContent('fourth')}
          >
            <img
              className={
                visibleSettings.find((item) => item.id === 'fourth').open
                  ? styles.infoBlock_arrow
                  : styles.infoBlock_arrowUp
              }
              src={coronaExpandable}
            />
            <p className={styles.infoBlock_expandText}>В Татарстане</p>
          </div>
          <div
            className={
              visibleSettings.find((item) => item.id === 'fourth').open
                ? styles.infoBlock_content
                : styles.displayNone
            }
          >
            <p className={styles.infoBlock_text_expand}>
              Два способа заказать пропуск для поездки на&nbsp;сделку:
            </p>

            <h4 className={styles.infoBlock_subheader}>
              Способ 1. Зарегистрироваться на сервисе&nbsp;
              <a
                className={styles.externalLink_nowrap}
                href="https://covid19.tatarstan.ru/"
                target="_blank"
              >
                covid19.tatarstan.ru
              </a>
            </h4>

            <p className={styles.infoBlock_text_expand}>
              В&nbsp;личном кабинете выбрать причину (посещение кредитной
              организации) и&nbsp;нажать кнопку &laquo;Получить код
              на&nbsp;выход&raquo;.
            </p>
            <img className={styles.infoBlock_img} src={tatarstan1} />

            <h4 className={styles.infoBlock_subheader}>
              Способ&nbsp;2. Отправить СМС на&nbsp;номер&nbsp;
              <a className={styles.externalLink_nounderline}>2590</a>
              &nbsp;с&nbsp;текстом: Цель 7
            </h4>

            <p className={styles.infoBlock_text_expand}>
              7&nbsp;&mdash; это номер для обозначения посещения кредитных
              организаций. В ответ вы&nbsp;получите СМС c&nbsp;кодом
              на&nbsp;выход.
            </p>

            <img className={styles.infoBlock_img} src={tatarstan2} />
          </div>

          <div className={styles.infoBlock_line}></div>

          <div id="ifFromRegion" className={styles.infoBlock}>
            <h4 className={styles.subheader}>
              Пустят&nbsp;ли меня на&nbsp;сделку в&nbsp;Москву или&nbsp;МО, если
              я&nbsp;еду из&nbsp;региона? Выпустят&nbsp;ли потом, что для этого
              нужно?
            </h4>
            <p className={styles.infoBlock_text_expand}>
              Если сделка проходит в&nbsp;другом городе, то&nbsp;на&nbsp;вас
              будут распространяться условия, действующие как в&nbsp;вашем
              городе (на&nbsp;выезд и&nbsp;въезд), так и&nbsp;в&nbsp;данном
              конкретном городе и&nbsp;регионе, где проходит сделка
              (на&nbsp;въезд и&nbsp;выезд).
            </p>
            <p className={styles.infoBlock_text_expand}>
              Если это города, где уже введен пропускной режим (Москва, МО,
              Татарстан), то&nbsp;вам нужно оформить пропуска через порталы,
              отвечающие за&nbsp;выдачу пропусков в&nbsp;данном конкретном
              городе. Для этого воспользуйтесь описанными выше инструкциями.
            </p>
          </div>

          <div id="whatToTellPolice" className={styles.infoBlock}>
            <h4 className={styles.subheader}>
              Что говорить и&nbsp;показывать полиции, если меня остановят
              на&nbsp;пути на&nbsp;сделку?
            </h4>
            <p className={styles.infoBlock_text}>
              Если вас останавливает полицейский и&nbsp;в&nbsp;городе действует
              пропускной режим, вы&nbsp;просто показываете полученный код
              и&nbsp;сообщаете цель поездки. Код пропуска предварительно
              необходимо записать, распечатать или сохранить на&nbsp;смартфоне.
            </p>
            <p className={styles.infoBlock_text}>
              Важно, что оштрафовать вас могут, только если
              вы&nbsp;в&nbsp;легкой форме переносите COVID-19 или вернулись
              из-за границы менее 14&nbsp;дней назад. В&nbsp;этом случае,
              пожалуйста, проявите заботу о&nbsp;себе и&nbsp;близких, останьтесь
              дома и&nbsp;перенесите сделку.
            </p>
          </div>

          <div id="moreInfo" className={styles.infoBlock}>
            <h4 className={styles.subheader}>
              Больше информации по&nbsp;пропускным режимам
            </h4>
            <div className={styles.regionBlock}>
              <img src={moscowImg} className={styles.regionImg} />
              <div className={styles.region}>
                <p className={styles.regionName}>Москва</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://mos.ru"
                >
                  mos.ru
                </a>
              </div>
            </div>

            <div className={styles.regionBlock}>
              <img src={moscowRegionImg} className={styles.regionImg} />
              <div className={styles.region}>
                <p className={styles.regionName}>Московская область</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://uslugi.mosreg.ru/"
                >
                  uslugi.mosreg
                </a>
              </div>
            </div>

            <div className={styles.regionBlock}>
              <img src={tatarstanRegionImg} className={styles.regionImg} />
              <div className={styles.region}>
                <p className={styles.regionName}>Татарстан</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://covid19.tatarstan.ru/"
                >
                  covid19.tatarstan.ru
                </a>
              </div>
            </div>

            <div className={styles.regionBlock}>
              <img src={spbRegionImg} className={styles.regionImg} />
              <div className={styles.region}>
                <p className={styles.regionName}>Санкт-Петербург</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://gov.spb.ru"
                >
                  gov.spb.ru
                </a>
              </div>
            </div>

            <p className={styles.infoBlock_text}>
              Ниже список субъектов&nbsp;РФ, где присутствуют наши
              банки-партнёры и&nbsp;действует рекомендательная пропускная
              система. Пожалуйста, перед поездкой на&nbsp;сделку еще раз
              проверьте актуальность информации по&nbsp;своему региону
              на&nbsp;официальном городском портале:
            </p>

            <div className={styles.regionBlock}>
              <img src={nizhegorodRegionImg} className={styles.regionImg} />
              <div className={styles.region}>
                <p className={styles.regionName}>Нижегородская область</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://government-nnov.ru"
                >
                  government-nnov.ru
                </a>
              </div>
            </div>

            <div className={styles.regionBlock}>
              <img src={samaraRegionImg} className={styles.regionImg} />
              <div className={styles.region}>
                <p className={styles.regionName}>Самарская область</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://samregion.ru"
                >
                  samregion.ru
                </a>
              </div>
            </div>

            <div className={styles.regionBlock}>
              <img src={krasnodarRegionImg} className={styles.regionImg} />
              <div className={styles.region}>
                <p className={styles.regionName}>Краснодарский край</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://minzdravkk.ru"
                >
                  minzdravkk.ru
                </a>
              </div>
            </div>

            <div className={styles.regionBlock}>
              <img src={bashkortostanRegionImg} className={styles.regionImg} />
              <div className={styles.region}>
                <p className={styles.regionName}>Республика Башкортостан</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="http://health.bashkortostan.ru"
                >
                  health.bashkortostan
                </a>
              </div>
            </div>

            <div className={styles.regionBlock}>
              <img
                src={astrakhanRegionImg}
                className={styles.regionImg_astrakhan}
              />
              <div className={styles.region}>
                <p className={styles.regionName}>Астраханская область</p>
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://stopcovid-19.astrobl.ru/"
                >
                  stopcovid-19
                </a>
              </div>
            </div>

            <div className={styles.fullPicture}>
              <p className={styles.infoBlock_text}>
                Полную картину эпидемиологической ситуации в&nbsp;РФ можно
                мониторить в&nbsp;режиме реального времени &mdash;&nbsp;
                <a
                  className={styles.regionLink}
                  target="_blank"
                  href="https://стопкоронавирус.рф"
                >
                  стопкоронавирус.рф
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CoronaReminder
