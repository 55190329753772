// extracted by mini-css-extract-plugin
export var card = "coronaReminder-module--card--d6256";
export var cardArrow = "coronaReminder-module--cardArrow--9818f";
export var cardText = "coronaReminder-module--cardText--becc7";
export var cardsBlock = "coronaReminder-module--cardsBlock--31c3e";
export var container = "coronaReminder-module--container--ad016";
export var content = "coronaReminder-module--content--cdd2f";
export var displayNone = "coronaReminder-module--displayNone--eaf42";
export var externalLink = "coronaReminder-module--externalLink--4bfde";
export var externalLink_nounderline = "coronaReminder-module--externalLink_nounderline--2f90b";
export var externalLink_nowrap = "coronaReminder-module--externalLink_nowrap--9485d";
export var externalLink_nowrap_nounderline = "coronaReminder-module--externalLink_nowrap_nounderline--c197b";
export var fullPicture = "coronaReminder-module--fullPicture--a31fe";
export var generalText = "coronaReminder-module--generalText--42a36";
export var header = "coronaReminder-module--header--15457";
export var infoBlock = "coronaReminder-module--infoBlock--ae4d6";
export var infoBlock_arrow = "coronaReminder-module--infoBlock_arrow--bf665";
export var infoBlock_arrowUp = "coronaReminder-module--infoBlock_arrowUp--0b5bd";
export var infoBlock_content = "coronaReminder-module--infoBlock_content--3be01";
export var infoBlock_expandButton = "coronaReminder-module--infoBlock_expandButton--14eca";
export var infoBlock_expandText = "coronaReminder-module--infoBlock_expandText--77a46";
export var infoBlock_img = "coronaReminder-module--infoBlock_img--fbaac";
export var infoBlock_line = "coronaReminder-module--infoBlock_line--e81f9";
export var infoBlock_link = "coronaReminder-module--infoBlock_link--c9151";
export var infoBlock_position = "coronaReminder-module--infoBlock_position--07579";
export var infoBlock_span = "coronaReminder-module--infoBlock_span--77194";
export var infoBlock_subheader = "coronaReminder-module--infoBlock_subheader--5c8f6";
export var infoBlock_text = "coronaReminder-module--infoBlock_text--25317";
export var infoBlock_text_expand = "coronaReminder-module--infoBlock_text_expand--fcbab";
export var listStyle = "coronaReminder-module--listStyle--ad99b";
export var logo = "coronaReminder-module--logo--f24db";
export var logolinkk = "coronaReminder-module--logolinkk--737cf";
export var region = "coronaReminder-module--region--f8c50";
export var regionBlock = "coronaReminder-module--regionBlock--c2111";
export var regionImg = "coronaReminder-module--regionImg--2c926";
export var regionImg_astrakhan = "coronaReminder-module--regionImg_astrakhan--4fdbc";
export var regionLink = "coronaReminder-module--regionLink--7f6d0";
export var regionName = "coronaReminder-module--regionName--f9f4c";
export var smsLayOut = "coronaReminder-module--smsLayOut--be21a";
export var smsLayOut_header = "coronaReminder-module--smsLayOut_header--b0ec9";
export var smsLayOut_text = "coronaReminder-module--smsLayOut_text--edac2";
export var smsLayOut_text_expand = "coronaReminder-module--smsLayOut_text_expand--f0971";
export var storeLink = "coronaReminder-module--storeLink--bd732";
export var storeLinkImg = "coronaReminder-module--storeLinkImg--66827";
export var storeLinksContainer = "coronaReminder-module--storeLinksContainer--4bbb7";
export var subheader = "coronaReminder-module--subheader--13ff1";
export var warningBlock = "coronaReminder-module--warningBlock--349d7";
export var warningImage = "coronaReminder-module--warningImage--8a3c6";
export var warningText = "coronaReminder-module--warningText--e4ec4";